.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar.default-nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

}

.form-container {
  min-height: calc(80vh - 70px);

  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 30px;
  max-width: 540px;
  
}

.form-container h3 {
  text-align: center;
  margin-bottom: 10px;
}


.info-div {
  padding-top: 50px;
}